import React from 'react';
import BaseLayoutTemplate from '../components/environments/BaseLayoutTemplate';
import ReactHelmet from 'react-helmet';

const GooglePlayPage = () => (
  <BaseLayoutTemplate>
    <ReactHelmet htmlAttributes={{ lang: 'ja' }}>
      <meta
        http-equiv='refresh'
        content='0;URL=https://play.google.com/store/apps/details?id=com.croccha.app'
      />
      <meta name='robots' content='noindex' />
    </ReactHelmet>

    <span>google playに遷移しています...</span>
  </BaseLayoutTemplate>
);

export default GooglePlayPage;
